<template>
  <b-modal
      :id="modalId"
      hide-header
      hide-footer
      hide-header-close
      size="xl"
      header-class="my-second-class"
      :body-bg-variant="'gray-lighten'"
  >
    <b-card>
      <iframe width="100%" height="900px" v-if="src" :src="src"/>
    </b-card>
    <b-row no-gutters>
      <d-button
          :text="'general.actions.dismiss'"
          :class="'d-btn btn-full-width btn d-btn-danger font-text-title'"
          @on:button-click="$bvModal.hide(modalId)"
      />
    </b-row>
  </b-modal>
</template>
<script>

export default {
  data: () => ({
    active: false,
    componentFormKey: 1,
  }),
  props: {
    modalId: {
      type: String,
      default: 'default'
    },
    src: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function () {
      this.active = false;
      this.componentFormKey += 1;
      this.$bvModal.show(this.modalId);
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style scoped>

/deep/ .modal {
  padding-top: 40px
}

/deep/ .modal-content {
  border-radius: 8px;
}
/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 0;
  border-radius: 8px;
}

@media (min-width: 1200px) {
  /deep/.modal-xl {
    min-width: 90%;
  }
}
/deep/ .card {
 margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/deep/.card .card-body {
  padding: 0;
  border: 2px solid #acaaaa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent;
}
</style>
